// src/App.js
import React from 'react';

import Header from './components/Header';
import Banner from './components/Banner';
import Footer from './components/Footer';
import Hero from './components/hero';
import GameCategories from './components/GameCategories';

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
   <Banner />
   <Hero/>
   <GameCategories/>
   <Footer />
   
    </div>
  );
}

export default App;
