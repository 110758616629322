import React, { useState, useEffect } from 'react';
import { ChevronDown, WhatsApp  } from 'lucide-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import banner_img3 from '../components/banner_img3.png';
import banner_img_desktop from '../components/banner_img_desktop.png'; // Import the desktop image

const Banner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setIsVisible(true);
    const handleMouseMove = (e) => {
      setMousePosition({
        x: (e.clientX - window.innerWidth / 2) / 50,
        y: (e.clientY - window.innerHeight / 2) / 50,
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <section className="relative mt-20 pt h-screen w-full overflow-hidden">
      {/* Background with parallax effect */}
      <div
        className="absolute inset-0  h-100 ml-5 bg-cover bg-center bg-no-repeat transition-transform duration-300 ease-out"
        style={{
          backgroundImage: `url(${window.innerWidth >= 768 ? banner_img_desktop : banner_img3})`, // Conditional image based on screen size
          transform: `translate(${mousePosition.x}px, ${mousePosition.y}px) scale(1.1)`,
          backgroundSize: 'cover', // Ensure the image covers the entire section
        }}
      />

      {/* Animated particles background */}
      <div className="absolute inset-0">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute w-2 h-1 bg-white rounded-full opacity-20 animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${5 + Math.random() * 10}s`,
            }}
          />
        ))}
      </div>

      {/* Main content */}
      <div className="relative h-full flex flex-col justify-center items-center text-white px-6">
        {/* Extra spacing for mobile view */}
        <div className="flex-grow"></div>

        {/* Buttons with enhanced hover effects and mobile adjustments */}
        <div
          className={`flex flex-col sm:flex-row gap-4 transform ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
          } transition-all duration-1000 delay-900 mt-auto pb-10 sm:pb-0`}
        >
          <a
            href="http://www.begumexch99.com"
            className="group relative px-6 py-3 sm:px-8 sm:py-4 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full overflow-hidden shadow-lg hover:shadow-blue-500/50 transition-all duration-300 text-center text-sm sm:text-lg font-semibold"
          >
            <div className="absolute inset-0 bg-white/20 transform -skew-x-12 translate-x-full group-hover:translate-x-0 transition-transform duration-300" />
            <span className="relative">Register Now</span>
          </a>

          <a
  href="https://wa.link/exchange247"
  className="group relative px-6 py-3 sm:px-8 sm:py-4 bg-gradient-to-r from-green-400 to-green-600 rounded-full overflow-hidden shadow-lg hover:shadow-blue-500/50 transition-all duration-300 text-center text-sm sm:text-lg font-semibold flex items-center space-x-2"
>
  <div className="absolute inset-0 bg-white/20 transform -skew-x-12 translate-x-full group-hover:translate-x-0 transition-transform duration-300" />
  <FontAwesomeIcon icon={faWhatsapp} className="relative w-5 h-5" />
  <span className="relative">Get ID On WhatsApp</span>
</a>
        </div>

       
      </div>
    </section>
  );
};

export default Banner;
