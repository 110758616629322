// Header.js

import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaTelegram, FaInstagram } from 'react-icons/fa'; // Import icons from react-icons
import logo from '../components/logo.png'; // Adjust the path to your logo image

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  // Detect scroll and toggle shadow
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`fixed top-0 w-full z-50 p-6 flex flex-col md:flex-row items-center justify-between transition-shadow duration-300 ${isScrolled ? 'shadow-lg' : ''}`}
      style={{
        background: 'linear-gradient(90deg, #003d73 0%, #005dbb 100%)', // Adjust the colors for the exact gradient
      }}
    >
      
      {/* Logo with link */}
      <div className="flex items-center space-x-4">
        <a href="http://www.begumexch99.com" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Logo" className="h-12 w-auto" />
        </a>
      </div>

      {/* Social Media Icons */}
      <div className="flex space-x-6 mt-4 md:mt-0 text-white">
        <a href="https://wa.link/exchange247" target="_blank" rel="noopener noreferrer" className="transition-transform transform hover:scale-125">
          <FaWhatsapp className="w-6 h-6 hover:text-green-400" />
        </a>
        <a href="https://t.me/begumexch99official" target="_blank" rel="noopener noreferrer" className="transition-transform transform hover:scale-125">
          <FaTelegram className="w-6 h-6 hover:text-blue-400" />
        </a>
        <a href="https://www.instagram.com/begumexch99official" target="_blank" rel="noopener noreferrer" className="transition-transform transform hover:scale-125">
          <FaInstagram className="w-6 h-6 hover:text-pink-400" />
        </a>
      </div>
    </header>
  );
};

export default Header;
