import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faUsers, faChartBar, faDice, faFutbol, faChessKnight, faCarCrash } from '@fortawesome/free-solid-svg-icons';

const GameCategories = () => {
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const categories = [
    {
      id: 'casino',
      title: 'CRICKET',
      icon: faDice,
      features: ['24/7 Live Support', 'Instant Withdrawals', 'Multiple Game Options']
    },
    {
      id: 'sports',
      title: 'FOOTBALL',
      icon: faFutbol,
      features: ['Live Betting', 'Best Odds', 'Multiple Sports']
    },
    {
      id: 'live-casino',
      title: 'TENNIS',
      icon: faChessKnight,
      features: ['Professional Dealers', 'Real-Time Gaming', 'Interactive Experience']
    },
    {
      id: 'games',
      title: 'KABADDI',
      icon: faTrophy,
      features: ['Card Games', 'Table Games', 'Instant Win']
    },
    {
      id: 'racing',
      title: 'HORSE RACING',
      icon: faCarCrash,
      features: ['Live Racing', 'Virtual Events', 'Best Odds']
    },
    {
      id: 'crash',
      title: '500+ LIVE CASINO',
      icon: faCarCrash,
      features: ['Instant Results', 'Live Multipliers', 'Quick Payouts']
    }
  ];

  return (
    <section className="relative min-h-screen bg-gradient-to-b from-blue-900 via-purple-900 to-blue-900 py-16 px-4">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-96 h-96 bg-blue-500/10 rounded-full -top-48 -left-48 blur-3xl" />
        <div className="absolute w-96 h-96 bg-purple-500/10 rounded-full -bottom-48 -right-48 blur-3xl" />
      </div>

      <div className="relative max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Experience Premium Gaming
          </h2>
          <div className="flex justify-center gap-8 text-white/80">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faTrophy} size="lg" />
              <span>Best Odds</span>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faUsers} size="lg" />
              <span>24/7 Support</span>
            </div>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faChartBar} size="lg" />
              <span>Instant Payouts</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categories.map((category, index) => (
            <a
              href="http://www.begumexch99.com"
              key={category.id}
              className="group relative rounded-2xl overflow-hidden shadow-xl transition-all duration-300 block"
              onMouseEnter={() => setHoveredCategory(category.id)}
              onMouseLeave={() => setHoveredCategory(null)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-900/90 to-purple-900/90 opacity-80 
                group-hover:opacity-90 transition-opacity duration-300" />
              <div className="aspect-w-16 aspect-h-9 flex justify-center items-center">
                <FontAwesomeIcon
                  icon={category.icon}
                  size="4x"
                  className="text-white group-hover:scale-110 transition-transform duration-500"
                />
              </div>
              <div className="absolute inset-0 p-6 flex flex-col justify-between">
                <h3 className="text-3xl font-bold text-white mb-4">
                  {category.title}
                </h3>
                <div className={`transform transition-all duration-300 ${hoveredCategory === category.id ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
                  <ul className="space-y-2 mb-6">
                    {category.features.map((feature, idx) => (
                      <li key={idx} className="text-white/90 flex items-center gap-2">
                        <span className="w-1.5 h-1.5 bg-white/80 rounded-full" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button className="w-full bg-white/10 hover:bg-white/20 text-white py-3 px-6 rounded-lg 
                    backdrop-blur-sm transition-all duration-300 border border-white/30 hover:border-white/50
                    transform group-hover:translate-y-0 translate-y-2">
                    Play Now
                  </button>
                </div>
                <div className="absolute top-0 right-0 w-16 h-16 bg-white/10 rounded-full -translate-y-8 translate-x-8 
                  group-hover:translate-y-0 group-hover:translate-x-0 transition-all duration-500" />
              </div>
            </a>
          ))}
        </div>

        <div className="mt-16 text-center">
          <a
            href="http://www.begumexch99.com"
            className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white px-8 py-4 rounded-lg
              text-lg font-semibold hover:from-blue-600 hover:to-purple-700 transform hover:scale-105 transition-all 
              duration-300 shadow-lg hover:shadow-xl border border-white/10"
          >
            Start Playing Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default GameCategories;
