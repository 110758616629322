import React from 'react';
import { Shield, Clock, Wallet, Gift, Trophy, Phone, ArrowRight, CheckCircle2, Coins,Check } from 'lucide-react';

const Hero = () => {
  const features = [
    { icon: Clock, text: "Self Signup Within 2 Mins", gradient: "from-blue-400 to-blue-600" },
    { icon: Wallet, text: "24*7 Self Deposit / Withdrawal", gradient: "from-purple-400 to-purple-600" },
    { icon: Trophy, text: "Withdrawal Within 30 Mins", gradient: "from-green-400 to-green-600" },
    { icon: Gift, text: "5% Bonus On Every Deposit", gradient: "from-yellow-400 to-yellow-600" },
    { icon: Coins, text: "Minimum ID 100 INR/-", gradient: "from-pink-400 to-pink-600" },
    { icon: Shield, text: "100% Safe & Secure", gradient: "from-indigo-400 to-indigo-600" },
    { icon: Check, text: "Super Fast Response", gradient: "from-red-400 to-red-600" },
    { icon: Phone, text: "24*7 Customer Support", gradient: "from-yellow-400 to-yellow-600" },
  ];

  return (
    <section className="relative pt-0 bg-gray-900 text-white overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-1/2 -left-1/2 w-[200%] h-[200%] bg-[radial-gradient(circle_at_center,_#1a237e_0%,_transparent_25%)] animate-pulse" />
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#000000_2px,transparent_2px),linear-gradient(to_bottom,#000000_2px,transparent_2px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_100%_100%_at_50%_50%,#000_70%,transparent_100%)] opacity-20" />
      </div>

      <div className="relative max-w-7xl mx-auto px-1 sm:px-6 lg:px-8">
        {/* Main Content */}
        <div className="text-center mb-16">
     

          {/* <h1 className="text-5xl md:text-7xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-400 to-blue-600">
            Register Now & Win
            <span className="block text-6xl md:text-8xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-600">
              Unlimited 💰
            </span>
          </h1> */}

          {/* CTA Buttons */}
       

          {/* Animated Stats Counter */}
          {/* <div className="flex justify-center gap-8 mb-16">
            {[ 
              { label: "Active Users", value: "100k+" },
              { label: "Daily Payouts", value: "₹1M+" },
              { label: "Success Rate", value: "100%" }
            ].map((stat) => (
              <div key={stat.label} className="text-center">
                <div className="text-3xl font-bold text-blue-400 mb-1">{stat.value}</div>
                <div className="text-sm text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div> */}
        </div>

        {/* Features Grid with Hover Effects */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group relative overflow-hidden rounded-xl bg-gray-800/50 backdrop-blur-sm border border-gray-700 p-6 hover:bg-gray-800/70 transition-all duration-300 hover:scale-105"
            >
              <div className="absolute inset-0 bg-gradient-to-r opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
              <div className="flex items-center space-x-4">
                <div className={`p-3 rounded-lg bg-gradient-to-r ${feature.gradient}`}>
                  <feature.icon className="h-6 w-6 text-white" />
                </div>
                <p className="font-semibold">{feature.text}</p>
              </div>
            </div>
            
          ))}

          
        </div>

        <div className="flex flex-col items-center sm:flex-row justify-center gap-4 mb-12">
            {/* <a href="http://www.begumexch99.com" target="_blank" rel="noopener noreferrer">
              <button className="group relative overflow-hidden rounded-lg bg-gradient-to-r from-blue-600 to-blue-600 700 px-8 py-4 font-bold transition-transform hover:scale-105">
                <div className="absolute inset-0 bg-white/20 transition-transform group-hover:translate-x-full" />
                <span className="relative flex items-center justify-center">
                  Register Now
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                </span>
              </button>
            </a> */}

            <a href="https://wa.me/918399927001" target="_blank" rel="noopener noreferrer">
              <button className="rounded-lg  bg-gradient-to-r from-green-400 to-green-600 px-8 py-4 font-bold hover:bg-white/20 transition-colors">
              Customer Support
              </button>
            </a>
          </div>
      </div>
    </section>
  );
};

export default Hero;
