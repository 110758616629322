import React from 'react';
import { Instagram, Send, Phone, Shield, Mail, Globe, ArrowUpRight } from 'lucide-react';

const Footer = () => {
  const socialLinks = [
    { 
      icon: Instagram, 
      href: 'https://www.instagram.com/begumexch99official', 
      label: 'Instagram',
      gradient: 'from-purple-500 to-pink-500'
    },
    { 
      icon: Send, 
      href: 'https://t.me/begumexch99official', 
      label: 'Telegram',
      gradient: 'from-blue-500 to-cyan-500'
    },
 
    { 
      icon: Globe, 
      href: 'http://www.begumexch99.com', 
      label: 'Website',
      gradient: 'from-teal-500 to-blue-500'
    }
  ];

  const quickLinks = [
    { text: "Terms and Conditions", href: "#" },
    { text: "Responsible Gaming", href: "#" },
    { text: "Privacy Policy", href: "#" },
    { text: "About Us", href: "#" },
    { text: "FAQ", href: "#" },
    { text: "Contact", href: "#" }
  ];

  return (
    <footer className="relative bg-gray-900 text-white overflow-hidden">
      {/* Decorative Background */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#000000_1px,transparent_1px),linear-gradient(to_bottom,#000000_1px,transparent_1px)] bg-[size:40px_40px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)] opacity-10" />
      
      <div className="relative">
        {/* Top Wave Separator */}
        <div className="absolute top-0 left-0 right-0 transform -translate-y-1/2">
          <svg className="w-full h-24 fill-gray-900" viewBox="0 0 1440 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h1440V0c-211.28 43.15-422.56 43.15-633.84 0C594.88 0 383.6 0 172.32 0 115.52 0 57.76 16 0 48Z"/>
          </svg>
        </div>

        {/* Main Footer Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-12">
          {/* Top Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-16">
            {/* Brand Section */}
            <div className="space-y-6">
              <h3 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
                BEGUM EXCHANGE OFFICIAL
              </h3>
              <p className="text-gray-400 max-w-md">
              Your trusted platform for online gaming, offering secure transactions and 24/7 customer support.

              </p>
              {/* Contact Info */}
              <div className="space-y-3">
                <a href="tel:+918399927001" className="flex items-center space-x-3 text-gray-300 hover:text-blue-400 transition-colors group">
               
                  <span>+91-8399927001</span>
                  <ArrowUpRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                </a>
           
              </div>
            </div>

            {/* Quick Links */}
          

            {/* Social Links & Newsletter */}
            <div className="space-y-6">
              <h3 className="text-lg font-semibold text-blue-400">Connect With Us</h3>
              <div className="flex space-x-4">
                {socialLinks.map(({ icon: Icon, href, label, gradient }) => (
                  <a
                    key={label}
                    href={href}
                    aria-label={label}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`p-3 rounded-lg bg-gradient-to-r ${gradient} transform hover:scale-110 transition-transform duration-200 hover:shadow-lg group`}
                  >
                    <Icon className="h-6 w-6 text-white" />
                  </a>
                ))}
              </div>

    
         
            </div>
          </div>

 
        
        </div>
      </div>
    </footer>
  );
};

export default Footer;
